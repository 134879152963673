import common from '../../assets/images/common.png'
import uncommon from '../../assets/images/uncommon.png'
import rare from '../../assets/images/rare.png'
import epic from '../../assets/images/epic.png'
import legendary from '../../assets/images/legendary.png'
import mythic from '../../assets/images/mythic.png'

import './index.css'

function Tiers(props:{
  attributes: number,
  supply: number,
}) {
  return(
    <div className='rarity'>
 
      <div className="rarity-list2">
        <div className='rarity-badge' style={{backgroundImage: `url(${common})`}}>{`Common ${props.supply}`}</div>
        <div className='rarity-linear rarity-linear-gray' />
        <div className='rarity-badge' style={{backgroundImage: `url(${uncommon})`}}>{`Uncommon`}<div> ${Math.ceil(props.supply * 60 / 100)}</div></div>
        <div className='rarity-linear rarity-linear-green' />
        <div className='rarity-badge' style={{backgroundImage: `url(${rare})`}}>{`Rare ${Math.ceil(props.supply * 35 / 100)}`}</div>
        <div className='rarity-linear rarity-linear-blue' />
        <div className='rarity-badge' style={{backgroundImage: `url(${epic})`}}>{`Epic ${Math.ceil(props.supply * 15 / 100)}`}</div>
        <div className='rarity-linear rarity-linear-purpure' />
        <div className='rarity-badge' style={{backgroundImage: `url(${legendary})`}}>{`Legendary ${Math.ceil(props.supply * 5 / 100)}`}</div>
        <div className='rarity-linear rarity-linear-red' />
        <div className='rarity-badge' style={{backgroundImage: `url(${mythic})`}}>{`Mythic ${Math.ceil(props.supply / 100)}`}</div>
      </div>
    </div>
  )
}

export default Tiers